<template>
    <v-container fluid fill-height>
        <v-row>
            <v-col></v-col>
            <v-col>
                <v-card class="elevation-6" style="min-width:320px">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>登录</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field prepend-icon="person" name="login" label="邮箱" type="text" v-model="username"></v-text-field>
                            <v-text-field id="password" prepend-icon="lock" name="password" label="密码" type="password" :rules="[rules.required, rules.counter]" v-model="password"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" to='/auth/local/register'>注册？</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="AuthLocal">登录</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col></v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from '@/api'

export default {
    components: {},
    data() {
        return {
            username: '',
            password: '',
            rules: {
                required: value => !!value || '必须',
                counter: value => value.length <= 12 && value.length >= 6 || '6 ~ 12个字符',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || '请检查邮件地址'
                }
            }
        }
    },
    props: [],
    methods: {
      AuthLocal() {
        if (!this.password || !this.username) {
            this.$alert.warning({ message: '检查用户邮箱或者密码' })
            return
        }
        api.auth_local({
            identifier: this.username,
            password: this.password,
        }).then(res => {
            this.$Event.emit('auth_done', res)
        })
      }
    },
    mounted: function() {

    },
    beforeDestroy() {
        this.password = null
        this.username = null
        this.rules = null
    },
}
</script>
<style>
/* .maindialog {
  width: 420px;
  min-width:300px;
  margin:0 auto;
} */
</style>